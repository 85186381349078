import AudioFileFormat from "./audioFileFormat";

export default class WavParser {
    #CCodeRiff = "RIFF";
    #CCodeDsf = "DSD ";
    #CCodeRf64 = "RF64";
    // private variables
    #fileName: string;
    #fileSize: number;
    #errorMessage: string;
    #audioFileFormat: AudioFileFormat | undefined;

    #EncodingEnum = {
        Unknown: "Unknown",
        PCM_Signed: "PCM_Signed",
        PCM_Unsigned: "PCM_Unsigned",
    };

    constructor(fileName: string, fileSize: number) {
        this.#fileName = fileName;
        this.#fileSize = fileSize;
        this.#errorMessage = "";
        //console.log(`==== Filename to check: "${fileName}" ====`);
    }

    // private functions
    #appendMessage(message: string) {
        if (this.#errorMessage.length === 0)
            this.#errorMessage += "Invalid Wav File:";

        this.#errorMessage += `\n${message}`;
    }

    #isValidFourCharCode(fourCharCode: number) {
    // check for valid ckID: all printable ASCII characters but not all spaces
    // Java chars represent Unicode, not ASCII characters, so it won't help to cast the bytes as char.
    // I'll just have to use bytes and hard-code the check for ASCII printable characters.
        const byteArray = [
            (fourCharCode >> 24) & 0xff, // use unsigned shift for highest byte
            (fourCharCode & 0x00ff0000) >> 16,
            (fourCharCode & 0x0000ff00) >> 8,
            fourCharCode & 0x000000ff,
        ];

        let spaceCount = 0;

        for (const byteValue of byteArray) {
            // if not a printable character
            // Note: If byteValue is in the range of 0x80 to 0xFF (aka The extended ASCII character set),
            // the conditional statement below will yield true (i.e., this method will return false)
            // regardless of whether byte is interpreted as signed or unsigned.
            if (byteValue < 32 || byteValue >= 127) {
                return false;
            }

            if (byteValue === 32) {
                spaceCount++;
            }
        }
        return spaceCount !== 4;
    }

    // public functions:
    getPcmConfig = () => {
        if (this.#audioFileFormat) {
            return this.#audioFileFormat;
        }
        return null;
    };

    getErrors = () => {
        return this.#errorMessage;
    };

    parseAudio = async (fileStream: ArrayBuffer, seekAndReadCallback: (position: number) => Promise<unknown>) => {
        const fourCc = new TextDecoder().decode(new Uint8Array(fileStream, 0, 4));

        switch (fourCc) {
        case this.#CCodeRiff:
        case this.#CCodeRf64:
            return this.parseWav(fileStream, seekAndReadCallback);
        case this.#CCodeDsf:
            return this.parseDsf(fileStream, seekAndReadCallback);
        default:
            this.#appendMessage(`Invalid container chunk ID ${fourCc}`);
            return;
        }
    };

    parseDsf = async (fileStream: ArrayBuffer, seekAndReadCallback: (position: number) => Promise<unknown>) => {
        // See: https://dsd-guide.com/sites/default/files/white-papers/DSFFileFormatSpec_E.pdf
        const decoder = new TextDecoder();
        const reader = new DataView(fileStream);
        let offset = 4; // already read the four CC, so start at byte 4
        const chunkSize = reader.getBigInt64(offset, true);

        if (chunkSize !== 28n) {
            this.#appendMessage(`Invalid chunk size ${chunkSize}`);
            return;
        }

        offset += 8;

        const fileSize = reader.getBigInt64(offset, true);

        offset += 16; // skip over pointer to metadata chunk

        const formatHeader = decoder.decode(new Uint8Array(fileStream, offset, 4));

        if (formatHeader !== "fmt ") {
            this.#appendMessage(`Invalid format chunk header ${formatHeader}`);
            return;
        }

        offset += 12; // Skip over format chunk size

        const formatVersion = reader.getUint32(offset, true);

        if (formatVersion !== 1) {
            this.#appendMessage(`Invalid format version ${formatVersion}`);
            return;
        }

        offset += 4;

        const formatId = reader.getUint32(offset, true);

        if (formatId !== 0) {
            this.#appendMessage(`Invalid format chunk header ${formatId}`);
            return;
        }

        offset += 4;

        const channelType = reader.getUint32(offset, true);

        if (channelType < 1 || channelType > 7) {
            this.#appendMessage(`Invalid channel type ${channelType}`);
            return;
        }

        offset += 4;

        const channelNumber = reader.getUint32(offset, true);

        if (channelType < 1 || channelType > 6) {
            this.#appendMessage(`Invalid channel number ${channelNumber}`);
            return;
        }

        offset += 4;

        const sampleFrequency = reader.getUint32(offset, true);

        offset += 4;

        const bitsPerSample = reader.getUint32(offset, true);

        if (bitsPerSample !== 1 && bitsPerSample !== 8) {
            this.#appendMessage(`Invalid bits per sample ${bitsPerSample}`);
            return;
        }

        offset += 4;

        const sampleCount = reader.getBigInt64(offset, true);
        const duration = sampleCount / BigInt(sampleFrequency);
        const durationNumber = Number(duration) * 1000;

        this.#audioFileFormat = new AudioFileFormat(
            "DSD",
            "DSD",
            "DSF",
            0,
            bitsPerSample,
            channelNumber,
            0,
            sampleFrequency,
            0,
            fileSize,
            durationNumber,
        );
        return this.#errorMessage.length === 0;
    };

    parseWav = async (fileStream: ArrayBuffer, seekAndReadCallback: (position: number) => Promise<unknown>) => {
        // Note: This method assumes that the file position is at the beginning
        const CCodeWave = "WAVE";
        const CCodeFmt = "fmt ";
        const CCodeData = "data";
        //const CCodeBext = "bext";
        const CCodeRf64 = "RF64";
        const CCodeDs64 = "DS64";
        const FormatTagPCM = 1; // linear PCM
        const FormatTagFloat = 3; // IEEE floating point

        let formatChunkScanned = false;
        let dataChunkScanned = false;

        // 'fmt ' chunk members
        let blockAlign = 0; // Block alignment in bytes (bytes per sample frame for PCM)
        let bitsPerSample = 0; // Sample size in bits, BitDepth
        let avgBytesPerSec = 0; // bit rate

        let frameCount = 0;
        let rf64DataChunkSize = 0n;

        let encoding = this.#EncodingEnum.Unknown;

        // read the container chunk
        let reader = new DataView(fileStream); // Assuming fileStream is an ArrayBuffer

        //let chunkID = reader.getInt32(0, true);
        // Read WAV header
        let chunkID = new TextDecoder().decode(new Uint8Array(fileStream, 0, 4));
        let chunkSizeInt = reader.getInt32(4, true); // NOTE: long, not int, to support >=2 Gigabyte UnsignedValue() solution

        if (chunkSizeInt < -1) {
            chunkSizeInt = reader.getUint32(4, true);
        }

        //let chunkSize = BigInt(chunkSizeInt >>> 0); // Zero-fill to make a 64-bit unsigned number
        let chunkSize = chunkSizeInt >>> 0; // Zero-fill to make a 64-bit unsigned number

        // File size is 4 bytes at offset + header size(4), unless it is RF64 then get it from the ds64 chunk at offset 8.
        let fileSize = BigInt(chunkSizeInt !== -1 ? chunkSizeInt + 8 : -1);
        //console.log(`ChunkID: ${chunkID} Size: ${chunkSize} bytes`);
        //let formatType = reader.getInt32(8, true);
        //let format = formatType;
        const format = new TextDecoder().decode(new Uint8Array(fileStream, 8, 4));
        const formatProfile = chunkID;
        const isRf64 = CCodeRf64 === chunkID;

        // validate the container chunk
        // verify chunkID
        if (this.#CCodeRiff !== chunkID && CCodeRf64 !== chunkID) {
            //console.log(`Invalid container chunk ID ${chunkID}`);
            this.#appendMessage(`Invalid container chunk ID ${chunkID}`);
        }

        // verify format
        if (format !== CCodeWave) {
            //console.log(`Invalid format type ${format}`);
            this.#appendMessage(`Disallowed format type ${format}`);
            return;
        }

        // parse through all chunks in the file
        // set fPos to the position of the first local chunk in the 'RIFF' container chunk
        let fPos = 12; // sizeof_ContainerChunk = _k_sizeofChunkHeader + sizeof(formType) -> (8+4)
        let endOfChunk = 0;

        // RF64
        if (isRf64 && chunkSizeInt === -1) {
            chunkID = new TextDecoder().decode(new Uint8Array(fileStream, fPos, 4));
            fPos += 4;

            //console.log(`RF64 ChunkID = ${chunkID}`);
            if (chunkID.toUpperCase() === CCodeDs64) {
                chunkSize = reader.getUint32(fPos, true); // RBo.
                fPos += 4;
                endOfChunk = fPos + chunkSize;
                //console.log(`ChunkID: ${chunkID} Size: ${chunkSize} bytes`);

                const rf64FileSize = reader.getBigInt64(fPos, true); // RBo.

                fileSize = rf64FileSize + 8n; // use this file size for RF64
                fPos += 8;
                rf64DataChunkSize = reader.getBigInt64(fPos, true); // RBo.
                fPos += 8;
                fPos += 8;
                /*console.log(
                    `RF64FileSize: ${rf64FileSize} RF64DataChunkSize: ${rf64DataChunkSize}, RF64SampleCount: ${rf64SampleCount}`
                );*/

                // Table
                const tableLength = reader.getUint32(fPos, true); // RBo.

                fPos += 4;
                for (let i = 0; i < tableLength; i++) {
                    chunkID = new TextDecoder().decode(
                        new Uint8Array(fileStream, fPos, 4),
                    );
                    fPos += 4;

                    //console.log(`RF64 SubChunkID:= ${tempStr} Size: ${rf64ChunkSize} bytes`);

                    fPos += 8;
                }
                // set the file position for the next iteration
                endOfChunk += endOfChunk & 1;

                // increment to even byte?    //    maybe this shouldn't be done
                if (fPos !== endOfChunk) {
                    fPos = endOfChunk;
                    // there's no need to execute a seek if we meet the while loop's exit condition
                }

                chunkSize = Number(rf64FileSize);
            }
        } // endof RF64

        /*console.log(
            `Format: ${format}, FormatProfile: ${formatProfile}, FileSize: ${fileSize} bytes`
        );*/

        const endOfFile = this.#fileSize; // actual file size
        const endOfRiff = chunkSize + 8;
        const endOfXxxx = endOfRiff < endOfFile ? endOfRiff : endOfFile;

        if (endOfFile < endOfRiff) {
            // console.log(
            //     `The reported end of the 'RIFF' container chunk (${endOfRiff} bytes) exceeds the end of the file (${endOfFile} bytes)`
            // );
            this.#appendMessage(
                "RIFF container size greater than length of file.",
            );
            // to be as robust as possible, we'll hold off on considering this an error until we determine that a crucial region
            // of a crucial chunk reportedly occupies the area beyond the eof
        } else if (endOfFile > endOfRiff) {
            // console.log(
            //     `The end of the file (${endOfFile} bytes) exceeds the end of the 'RIFF' container chunk (${endOfRiff} bytes)`
            // );
            this.#appendMessage(
                "The file size is smaller than RIFF container size.",
            );
        }

        let samplesPerSec = 0;
        let numOfChannels = 0;
        let prevPos = 0;

        // JavaScript code for processing WAV file chunks
        while (fPos < endOfXxxx) {
            if (fPos - prevPos + 64 > fileStream.byteLength) {
                // allow a 64-byte padding for reading chunk info in this iteration; go get more from the file.
                //console.log("Getting more data from the file at offset...", fPos);

                const content = await seekAndReadCallback(fPos) as ArrayBuffer;

                fileStream = content;
                reader = new DataView(fileStream);
                prevPos = fPos;
                fPos = 0; // reset to beginning of the buffer
            } else {
                fPos = fPos - prevPos;
            }

            // read the header of the next chunk
            const chkId = reader.getUint32(fPos, true);

            chunkID = new TextDecoder().decode(new Uint8Array(fileStream, fPos, 4));
            fPos += 4;

            if (!this.#isValidFourCharCode(chkId)) {
                // console.log(`Invalid chunk ${chunkID} at byte ${fPos}`);
                this.#appendMessage(
                    `Unknown chunk ${chunkID} at offset ${fPos}`,
                );
                return;
            }

            chunkSize = reader.getUint32(fPos, true);
            chunkSizeInt = reader.getInt32(fPos, true);
            // console.log(`ChunkID: ${chunkID} Size: ${chunkSizeInt} bytes`);
            fPos += 4; // used to be ChunkHeaderSize; // advance over the chunk header (that is advance fPos to the chunk data)

            if (chunkID === CCodeData && isRf64) {
                // RF64
                chunkSize = Number(rf64DataChunkSize);
            }

            endOfChunk = fPos + chunkSize + prevPos;

            if (endOfChunk > endOfXxxx) {
                if (endOfChunk > endOfRiff) {
                    // console.log(
                    //     `The reported end of the ${chunkID} chunk (${endOfChunk} bytes)` +
                    //     ` exceeds the end of its containing 'RIFF' chunk (${endOfRiff} bytes)`
                    // );
                    this.#appendMessage(
                        `${chunkID} chunk size exceeds RIFF container size.`,
                    );
                }

                if (endOfChunk > endOfFile) {
                    // console.log(
                    //     `The reported end of the ${chunkID} chunk (${endOfChunk} bytes)` +
                    //     ` exceeds the end of the file (${endOfFile} bytes)`
                    // );
                    this.#appendMessage(
                        `${chunkID} container size greater than length of file.`,
                    );
                }

                // to be as robust as possible, we'll hold off on considering this an error until we determine (on a chunk by chunk basis)
                // that a crucial region of a crucial chunk reportedly occupies the area beyond the actual eof
            }

            switch (chunkID) {
            case CCodeFmt:
                // Handle 'fmt ' chunk
                formatChunkScanned = true;

                // check for screw-ups
                if (endOfChunk > endOfFile) {
                    // console.log(
                    //     "Crucial portion of the 'fmt ' chunk truncated by premature end of file"
                    // );
                    this.#appendMessage(
                        "FMT container size greater than length of file.",
                    );
                }

                if (chunkSize < 16) {
                    /* 2 + 2 + 4 + 4 + 2 + 2 = 16*/
                    // 16 = sizeof(formatTag, channels, samplesPerSec, avgBytesPerSec, blockAlign, bitsPerSample)
                    // console.log("'fmt ' chunk is too small");
                    this.#appendMessage("FMT container is too small.");
                }

                // read the relevant chunk data
                const formatTag = reader.getInt16(fPos, true); // RBo. Number indicating WAVE format category; should be at offset 20

                fPos += 2;
                numOfChannels = reader.getInt16(fPos, true); // RBo. Number of channels
                fPos += 2;
                samplesPerSec = reader.getInt32(fPos, true); // RBo. Sampling rate in sample frames per second
                fPos += 4;
                avgBytesPerSec = reader.getInt32(fPos, true);
                fPos += 4;
                blockAlign = reader.getUint16(fPos, true); // RBo.
                fPos += 2;
                // bytes per sample frame
                bitsPerSample = reader.getInt16(fPos, true); // RBo.
                fPos += 2;

                //console.log($"File position = {fileStream.Position} vs. fPos = {fPos}");
                if (chunkSize === 18 && FormatTagPCM === formatTag) {
                    //const cbSize
                    reader.getInt16(fPos, true); // RBo.
                    fPos += 2;
                }

                // check the encoding type
                if (FormatTagPCM === formatTag || FormatTagFloat === formatTag) {
                    encoding =
                      bitsPerSample === 8
                          ? this.#EncodingEnum.PCM_Unsigned
                          : this.#EncodingEnum.PCM_Signed;
                } else if (formatTag === -2) {
                    if (numOfChannels > 2) {
                        encoding =
                            bitsPerSample === 8
                                ? this.#EncodingEnum.PCM_Unsigned
                                : this.#EncodingEnum.PCM_Signed;
                    } else {
                        // console.log("Unsupported encoding type: WAVE_FORMAT_EXTENSIBLE.");
                        this.#appendMessage(
                            "WAVE_FORMAT_EXTENSIBLE encoding is not supported.",
                        );
                    }
                } else {
                    // ASPEN doesn't support any other codecs embedded in WAVE files
                    // console.log(`Unsupported encoding type: ${formatTag}`);
                    this.#appendMessage(`Encoding type ${formatTag} is not supported.`);
                }

                const computedBlockAlign = numOfChannels * (bitsPerSample / 8);
                // Similarly, doing arithmetic on ints causes the operands to be needlessly widened to longs.
                // What an astounding imposition.
                const computedAvgBytesPerSec = samplesPerSec * computedBlockAlign;

                // Sanity check result: we'll append messages about the incorrect values to #errorMessage
                if (computedAvgBytesPerSec !== avgBytesPerSec) {
                    // console.log(
                    //     `Incorrect AvgBytesPerSec in fmt chunk: is ${avgBytesPerSec}, should be computed_dwAvgBytesPerSec`
                    // );
                    this.#appendMessage(
                        "fmt chunk has incorrect AvgBytesPerSec.",
                    );
                }

                if (computedBlockAlign !== blockAlign) {
                    // console.log(
                    //     `Incorrect BlockAlign in 'fmt ' chunk: is ${blockAlign}, should be ${computedBlockAlign}`
                    // );
                    this.#appendMessage(
                        "fmt chunk has incorrect BlockAlign.",
                    );
                    // wBlockAlign is referenced in the 'data' chunk block, so replace it here
                    blockAlign = computedBlockAlign;
                }

                break; // end of case CCodeFmt

            case CCodeData:
                // Handle 'data' chunk
                dataChunkScanned = true;

                // check for screw-ups
                if (endOfChunk > endOfFile) {
                    // console.log(
                    //     "Crucial portion of the 'data' chunk truncated by premature end of file."
                    // );
                    this.#appendMessage(
                        "DATA chunk exceeds length of file.",
                    );
                }

                // Derive frameCount from the data chunk length (provided that we know wBlockAlign).
                if (formatChunkScanned) {
                    frameCount = Math.floor(chunkSize / blockAlign);
                } else {
                    // console.log(
                    //     "The 'fmt ' chunk must appear before the 'data' chunk."
                    // );
                    this.#appendMessage(
                        "FMT chunk not found before DATA chunk.",
                    );
                }

                if (frameCount === 0) {
                    // console.log("No Audio in the file.");
                    this.#appendMessage("No Audio in the file.");
                }

                //if (chunkSize % 2 !== 0 && reader.getInt8(fPos, true) !== 0) {
                //    fPos--; // compensate for the byte just read
                //}

                /* Not needed for this story AT-3581
                // Total number of samples cannot be odd for a Stereo file 6/7/23 RB and EC
                var numOfSamples = chunkSize / (bitsPerSample / 8);

                if (numOfChannels === 2 && numOfSamples % 2 !== 0) {
                    console.log(
                        `The file contains an odd number of samples ${numOfSamples}`
                    );
                    this.#appendMessage(
                        "Odd number of audio samples detected, must be even number."
                    );
                } */

                break; // end of case CCodeData
            } // endof switch

            // set the file position for the next iteration
            endOfChunk += endOfChunk & 1;

            // increment to even byte?    // maybe this shouldn't be done
            if (fPos !== endOfChunk) {
                fPos = endOfChunk;
                // there's no need to execute a seek if we meet the while loop's exit condition
            }
        } // endof while parse through all chunks in the file

        if (!formatChunkScanned) {
            // console.log("Missing 'fmt ' chunk.");
            this.#appendMessage("Missing FMT container.");
        }

        /* Sometimes we can't get to the data chunk because it is at the end of the file! */
        if (!formatChunkScanned && !dataChunkScanned) {
            // console.log("Missing 'data' chunk.");
            this.#appendMessage("Missing DATA container.");
        }

        const bitRate = avgBytesPerSec * 8; // -> bits-per-second
        const durationMS = Math.round((frameCount * 1000) / samplesPerSec); // duration in milliseconds

        // console.log(
        //     `${format} ${formatProfile} ${encoding} BitRate=${bitRate} SampleRate=${samplesPerSec} Bits=${bitsPerSample} Chan=${numOfChannels} Frames=${frameCount} DurationMS=${durationMS}`
        // );

        // instantiate the AudioFileFormat object
        this.#audioFileFormat = new AudioFileFormat(
            format,
            formatProfile,
            encoding, // EncodingEnum encoding
            bitRate, // BitRate
            bitsPerSample, // int BitDepth
            numOfChannels, // int Channels
            blockAlign, // int FrameSize
            samplesPerSec, // float FrameRate
            frameCount, // long FrameCount
            fileSize, // calculated file size
            durationMS, // duration in milliseconds
        );
        return this.#errorMessage.length === 0; // success if no erros
    }; // end of parseWav
} // end of WavChecker
