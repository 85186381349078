import { useStore } from "../State/zustandStore";
import {CommandAction, IngestionMessage, MessageScope, ValidationMessageType} from "../asset-service-api";
import {ITrack} from "../Projects/MapData";
import {IAsperaResource} from "../Projects/Actions/AsperaStatusButtonIcon";
import {useProjects} from "../Behaviors/projects";
import { useIngestionJobs, isJobRunning } from "../Behaviors/ingest.api";
import { UploadStatus } from "../api";
import {useApp} from "../Behaviors/app";
import {useHdValidator} from "./hd-bitdepth-validator";
import {useDurationDeltaValidators} from "./duration-delta-warning";
import {useBitDepthValidators} from "./bitDepthValidator";
import {useDolbyValidators} from "./doblyAudioValidator";
import {useSdValidator} from "./sd-bitdepth-validator";

export interface MessagePredicate {
    (message: IngestionMessage): boolean
}

export function errorFilter(messages: IngestionMessage[], predicate?: MessagePredicate) {
    return messages.filter(message =>
        ((message.messageType === ValidationMessageType.Exception) || (message.messageType === ValidationMessageType.Error))
        && (message.source?.id !== "D5E689A0-6B90-407E-A101-23BCCE2A3F7F") // (must have at least one file. We deal with this in the UI
        && (message.source?.id !== "92A33EA4-FD71-4E33-B137-3E10AFF8B574") // (resource is not uploaded... rewritten as a pending upload in get pending uploads.
        && (!predicate || predicate(message))); // if there is no predicate, or the predicate passes.
}

// Remove any local messages that have corresponding job messages
type IdPair = [remote: string, local: string];

const filterLocalMessages = (messages: IngestionMessage[], enableHdForSd: boolean) => {
    let filteredMessages = messages;
    const idPairs = [
        ["ADB0DB25-CFCF-4ABE-B98C-E32933BAEFCD", "601950E4-080F-4FB7-84A8-7D66C0879A96"], // Duration discrepency warning
        ["D3AF131B-A47A-466E-9BC9-9EB07E3B6E70", "601950E4-080F-4FB7-84A8-7D66C0879A96"], // Duration discrepency restriction
        ["C7BA44F2-33BB-4787-956D-85FA86B256D5", "3bfe2ac5-6cd4-4ebd-9977-89d4c65ba28a"], // Not an Atmos file
        ["E0C4DEC8-8705-41FC-8D28-FDD7A2AC8D8D", "7e7f7c03-5c8b-46bb-b62f-6b237a9d0f25"], // Invalid HD bit depth wav
        ["E0C4DEC8-8705-41FC-8D28-FDD7A2AC8D8D", "d27e37af-5bce-440b-8ccb-794f47919f6b"], // Invalid HD sample rate dsf
        ["C7BA44F2-33BB-4787-956D-85FA86B256D5", "05377e7b-8936-4112-b45d-e362c46d2ff2"], // Invalid HD channel count
    ] as IdPair[];

    if (!enableHdForSd) {
        idPairs.concat(
            ["E0C4DEC8-8705-41FC-8D28-FDD7A2AC8D8D", "6C6E5CAE-638D-406C-9FDB-CD665BC6AE4C"], // Invalid SD bit depth
            ["C7BA44F2-33BB-4787-956D-85FA86B256D5", "6C6E5CAE-638D-406C-9FDB-CD665BC6AE4C"], // Invalid SD channel count
        );
    }

    for (const id of idPairs) {
        const remoteMessages = filteredMessages.filter(m => m.source?.id === id[0]);

        for (const message of remoteMessages) {
            const duplicates = filteredMessages.filter(m => m.source?.id === id[1] && m.source.target === message.source?.target && message.messageType !== ValidationMessageType.Skipped);

            filteredMessages = filteredMessages.filter(m => !duplicates.includes(m));
        }
    }
    return filteredMessages;
};

export const useValidation = () => {

    const {getBitDepthInconsistentErrors, getSampleRateInconsistentErrors} = useBitDepthValidators();
    const {getDolbyAtmosErrors} = useDolbyValidators();
    const {getSDValidationErrors} = useSdValidator();
    const {getHDValidationErrors} = useHdValidator();
    const {durationDeltaWarning} = useDurationDeltaValidators();
    const app = useApp();
    //const ingestionState = useIngestionJobState();
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const {project, getMappedResources, getMappedResource} = useProjects(projectId!);
    const {getJob} = useIngestionJobs();

    function getLocalValidationMessages(jobId: string | null | undefined) {
        return getHDValidationErrors()
            .concat(getSDValidationErrors())
            .concat(durationDeltaWarning())
            .concat(getSampleRateInconsistentErrors())
            .concat(getBitDepthInconsistentErrors())
            .concat(getDolbyAtmosErrors());

    }

    function getValidationMessages(jobId: string | null | undefined) {
        const job = jobId ? getJob(jobId) : undefined;
        let isIngestJob = false;

        if (job) {
            isIngestJob = job.command?.action === CommandAction.Ingest;
        }

        const messages = jobId ? getJob(jobId)?.messages ?? [] : [];
        const result = messages
            .map(x => x.source?.id === "D5E689A0-6B90-407E-A101-23BCCE2A3F7F" ? { //"No resources found."
                ...x, message: "At least one file must be mapped. ",
            } : x)
            .map(x => x.source?.id === "41462AA6-CC37-40CA-BFDB-289BA8D496C9" ? { // Track numbers must match upc.
                ...x,
                messageType: ValidationMessageType.Instruction,
                message: "Drop a file from the left to map it to this track.",
            } : x)
            .concat(isIngestJob ? [] : getLocalValidationMessages(jobId));
        const filteredMessages = filterLocalMessages(result, app.features.enableHdForSd);
        return filteredMessages;
    }

    function getValidationRestrictions(jobId: string | null | undefined, predicate?: MessagePredicate) {
        const messages = getValidationMessages(jobId);
        const result = messages.filter(message =>
            ((message.messageType === ValidationMessageType.Restricted || message.messageType === ValidationMessageType.RestrictedResolvable || message.messageType === ValidationMessageType.RestrictedResolved))
            && (!predicate || predicate(message)), // if there is no predicate, or the predicate passes.
        );
        return result;
    }

    function getValidationWarnings(jobId: string | null | undefined, predicate?: MessagePredicate) {
        const messages = getValidationMessages(jobId);
        return messages.filter(message =>
            ((message.messageType === ValidationMessageType.Warning))
            && (!predicate || predicate(message)), // if there is no predicate, or the predicate passes.
        );
    }

    function getValidationInstructions(jobId: string | null | undefined, predicate?: MessagePredicate) {
        const messages = getValidationMessages(jobId);
        const result = messages.filter(message =>
            ((message.messageType === ValidationMessageType.Instruction))
            && (!predicate || predicate(message)), // if there is no predicate, or the predicate passes.
        );
        return result;
    }

    function getValidationErrors(jobId: string | null | undefined, predicate?: MessagePredicate) {
        const messages = getValidationMessages(jobId);
        return errorFilter(messages, predicate);
    }

    function getTrackValidationWarnings(jobId: string | null | undefined, track: ITrack) {
        const result = getValidationWarnings(jobId,
            ((x) => ((x.source?.scope === MessageScope.Track || x.source?.scope === MessageScope.TrackGroup) && x.source.target === track.number.toString())));
        return result;
    }

    function getTrackValidationRestrictions(jobId: string | null | undefined, track: ITrack) {
        const messages = getValidationMessages(jobId);
        const restrictions = messages.filter(message => (message.messageType === ValidationMessageType.Restricted)
            || (message.messageType === ValidationMessageType.RestrictedResolvable) || (message.messageType === ValidationMessageType.RestrictedResolved));
        const result = restrictions.filter((x) => ((x.source?.scope === MessageScope.Track || x.source?.scope === MessageScope.TrackGroup) && x.source.target === track.number.toString()));
        return result;
    }
    function hasTrackValidationRestrictions(jobId: string | null | undefined, track: ITrack) {
        return getTrackValidationRestrictions(jobId, track).length > 0;
    }

    function getTrackValidationInstructions(jobId: string | null | undefined, track: ITrack, hasFile: boolean) {
        const result = getValidationInstructions(jobId, (
            (x) => ((x.source?.scope === MessageScope.Track || x.source?.scope === MessageScope.TrackGroup)
                && x.source.target === track.number.toString() // this track.
                && (!hasFile || x.source.id !== "41462AA6-CC37-40CA-BFDB-289BA8D496C9")) // drop a file
        ),
        );
        return result;
    }

    function getTrackValidationErrors(jobId: string | null | undefined, track: ITrack) {
        const result = getValidationErrors(jobId, (
            (x) => ((x.source?.scope === MessageScope.Track || x.source?.scope === MessageScope.TrackGroup) && x.source.target === track.number.toString()) // this track.
        ),
        );
        return result;
    }

    function getProductValidationWarnings(jobId: string | null | undefined) {
        return getValidationWarnings(jobId, (x) => x.source?.scope === MessageScope.Product);
    }

    function getProductValidationErrors(jobId: string | null | undefined) {
        return getValidationErrors(jobId, (x) => x.source?.scope === MessageScope.Product);
    }

    function getProductValidationRestrictions(jobId: string | null | undefined) {
        return getValidationRestrictions(jobId, (x) => x.source?.scope === MessageScope.Product);
    }

    function hasValidationErrors(jobId: string | null | undefined) {
        return getValidationErrors(jobId).length > 0;
    }

    function hasValidationRestrictions(jobId: string | null | undefined) {
        return getValidationRestrictions(jobId).length > 0;
    }

    function hasValidationWarnings(jobId: string | null | undefined) {
        return getValidationWarnings(jobId).length > 0;
    }

    function hasProductValidationErrors(jobId: string | null | undefined) {
        return getProductValidationErrors(jobId).length > 0;
    }

    function hasProductValidationRestrictions(jobId: string | null | undefined) {
        return getProductValidationRestrictions(jobId).length > 0;
    }
    function hasUnresolvedValidationRestrictions(jobId: string | null | undefined) {
        return getValidationRestrictions(jobId, (x) => x.messageType !== ValidationMessageType.RestrictedResolved).length > 0;
    }

    function hasOverridableValidationRestrictions(jobId: string | null | undefined) {
        return getValidationRestrictions(jobId, (x) => x.messageType === ValidationMessageType.RestrictedResolvable || x.messageType === ValidationMessageType.RestrictedResolved).length > 0;
    }

    function hasProductValidationWarnings(jobId: string | null | undefined) {
        return getProductValidationWarnings(jobId).length > 0;
    }

    function hasTrackValidationWarnings(jobId: string | null | undefined, track: ITrack) {
        return getTrackValidationWarnings(jobId, track).length > 0;
    }

    function hasTrackValidationInstructions(jobId: string | null | undefined, track: ITrack, hasFile: boolean) {
        return getTrackValidationInstructions(jobId, track, hasFile).length > 0;
    }
    function hasTrackValidationErrors(jobId: string | null | undefined, track: ITrack) {
        return getTrackValidationErrors(jobId, track).length > 0;
    }

    // TODO: fix
    function isIngestionJobShown() {
        //return ingestionState.isIngestInProgress || ingestionState.isIngestCompleteOrFailed;
    }

    const isValidationInProgress = (jobId: string | null | undefined) => {
        if (jobId) {
            const job = getJob(jobId);
            return job && (job.command?.action === CommandAction.Validate && isJobRunning(job.state!, job.command.action));
        }
        return false;
    };

    function getPendingUploadMessages(jobId : string | null | undefined) {

        if(!project?.isIngestRequestedAfterUpload) return [];
        return getValidationMessages(jobId)
            .filter(x => (x.source!.id === "6B360297-7B37-498A-A2FA-B546F148ED4A") && ((x.source?.scope === MessageScope.Track || x.source?.scope === MessageScope.TrackGroup)) && x.messageType === ValidationMessageType.Error)
            .map(x => x.source?.id === "6B360297-7B37-498A-A2FA-B546F148ED4A" ? { //"No resources found."
                ...x, message: "Waiting for upload to complete.",
                messageType: "UploadInProgress",

            } : x);
    }

    function getPendingUploads() {
        const mappedResources = getMappedResources();
        return mappedResources.filter(x => x.uploadStatus !== UploadStatus.Complete);
    }

    function hasPendingUpload(): boolean {
        const pendingResources = getPendingUploads();
        return pendingResources.length > 0;
    }

    function hasTrackPendingUpload(track: ITrack) {
        const resource = getMappedResource(track);

        if (resource as IAsperaResource) {
            return (resource?.uploadStatus !== UploadStatus.Complete);
        }
        return false;
    }
    return {
        isIngestionJobShown,
        isValidationInProgress,
        hasValidationErrors,
        getValidationErrors,
        getValidationRestrictions,
        hasValidationRestrictions,
        hasUnresolvedValidationRestrictions,
        hasOverridableValidationRestrictions,
        getValidationWarnings,
        hasValidationWarnings,
        hasProductValidationErrors,
        getProductValidationErrors,
        hasProductValidationRestrictions,
        getProductValidationRestrictions,
        hasProductValidationWarnings,
        getProductValidationWarnings,
        hasTrackValidationWarnings,
        getTrackValidationWarnings,
        hasTrackValidationErrors,
        getTrackValidationErrors,
        getPendingUploadMessages,
        hasPendingUpload,
        hasTrackPendingUpload,
        getValidationMessages,
        getValidationInstructions,
        getTrackValidationInstructions,
        hasTrackValidationInstructions,
        hasTrackValidationRestrictions,
        getTrackValidationRestrictions,
    };
};
