import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import {FormEvent, useMemo, useState} from "react";
import {LinearProgress} from "@mui/material";

export interface Props {
    visible: boolean
    onUpcPicked: (upc: string) => void;
    loading: boolean;
    errorMessage: string
}

export const UpcPicker = (props: Props) => {
    const apiErrorMessage = useMemo(() => props.errorMessage, [props]);
    const [errorMessage, setErrorMessage] = useState("");

    async function handleSubmit(event: FormEvent) {

        event.preventDefault();
        event.stopPropagation();

        const target = event.target as unknown as { upc: { value: string } };
        let upcText = target.upc.value;

        upcText = upcText.trim();
        upcText = upcText.trimStart();
        upcText = upcText.substring(0, 14);

        const isNumeric = /^[0-9]+$/.test(upcText);

        if (!isNumeric || upcText.length > 14 || upcText.length === 0) {
            setErrorMessage("UPC must be a number with 14 digits or less.");
            return;
        }

        if (upcText === "00000000000000") {
            setErrorMessage(`${target.upc.value } is not a valid upc.`);
            return;
        }

        setErrorMessage("");
        props.onUpcPicked(upcText);
    }

    if (props.visible) {
        if (props.loading) {
            return (
                <Form onSubmit={handleSubmit} className={"m-3"}>
                    <Row className="align-items-center">
                        <Col className="align-items-center">

                            <LinearProgress />
                        </Col>
                        <Col xs="auto">
                            <Button disabled={true} type="submit" className="mb-4">
                                Loading...
                            </Button>
                        </Col>
                    </Row>
                </Form>
            );
        }
        return (
            <Form style={{ maxWidth: 500 }} autoComplete="off" onSubmit={handleSubmit}>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={1} className={"col-form-label"}>UPC</Form.Label>
                    <Col lg={7}>
                        <Form.Control name="upc" defaultValue={""}
                            aria-label={"upc"}
                            placeholder={"00000000000000"} isValid={errorMessage === ""}
                            isInvalid={errorMessage !== ""}
                        />
                        <Form.Control.Feedback aria-label="UPC Error Feedback" type="invalid">
                            {errorMessage}
                        </Form.Control.Feedback>
                    </Col>
                    <Col lg={3}>
                        <Button aria-label={"submit"} type="submit" className="mb-4">
                            Submit
                        </Button>
                    </Col>
                </Form.Group>
                <Row>
                    <Col>
                        {apiErrorMessage === "" ? null :
                            <Alert aria-label={"UPC Error Message"} variant="danger"> {apiErrorMessage}</Alert>}
                    </Col>
                </Row>
            </Form>
        );
    } else return null;
};
